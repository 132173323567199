<template>
  <b-form-textarea
      id="textarea-default"
      rows="3"
      cols="12"    />
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import { BFormTextarea } from 'bootstrap-vue'
import { codeDefault } from './code'

export default {
  components: {
    BCardCode,
    BFormTextarea
  },
  data() {
    return {
      codeDefault,
    }
  },
}
</script>
